.product-carousel {
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.product-carousel .carousel-control-prev,
.product-carousel .carousel-control-next {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 10px;
}

.product-carousel .carousel-indicators {
  margin-bottom: 0;
  padding: 10px 0;
}

.product-carousel .carousel-indicators button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
}

.product-carousel img {
  object-fit: contain;
  height: 400px;
  background: #fff;
}

.image-container {
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.hidden {
  visibility: hidden;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 