/* NavBar */
header{
    box-shadow:  0px 5px 5px -3px rgba(50, 50, 93, 0.25);   
}

/* searchbox */

.search-btn{
    color: tomato; 
}
.search-form{
    border: 1px solid tomato;
    border-radius: 3%;
    margin: 60px;
}

/* Product */

.home-card{
    border: 1px solid green;
    height: 30rem;
}

.card-btn:hover{
    background-color: grey;
}

.footer{
    border-top-width:5px;  
    border-top-style:groove;
}

/* product screen */

.cart-button:hover{
    color: black;
    background-color: tomato;
}

.review-section{
    margin-top: 80px;
    margin-bottom: 50px;
}

.show-review{
   background-color: grey;
   color:white;
   padding: 10px;
   
}

.accordion-header{
    background-color: grey;
}

/* cart Screen */

.trash-icon{
    color: red;
}

.cart-header{
    background-color: grey;
    color:white;
}

.btn-cart:hover{
     background-color:tomato;
}

/* login screen */

.signin-btn:hover{
    background-color: tomato;
}

/* payment screen */

.payment-header{
    background-color: grey;
    color: white;
}

.payment-container{
    margin-top: 100px;
}

/* place order screen */

.shipping-section{
    margin-top: 50px;
}

.shipping{
    background-color: green;
    padding: 10px;
    
}

.payment{
    background-color: rgb(60, 22, 197);
    padding: 10px;
}

.item-section{
    background-color: grey;

}

.item-header{
    background-color: tomato;
    padding: 10px;
}

.summary-header{
    background-color: grey;
    color: white;
}

/* order screen */

.order-id{
    background-color: rgb(19, 231, 125);
    padding: 10px;
}

/* contact screen */

.contact-header{
    text-align: center;
    padding: 50px;
}

.contact-end{
    background-color:#2c365d;
    margin: 20px;
    padding: 10px;
    color: white;
    border: 2px solid black;
    border-radius: 15px;
    margin-top: 80px;
}

.contact-end h6{
   
    color: white;
 
}

/* Profile screen */

.profile-section{
    background-color: #272343;
    color: white;
    border-radius: 10px;
}

.profile-section h2{
    color: white;
}

/* .order-section th{
    background-color:#b68973;
    color: white;
} */

/* user list screen */

/* .userlist th{
    background-color: #b68973 ;
    color: white;
} */

/* user edit screen */

.editscreen{
    background-color: #272343;
    border: 5px solid sienna;
    color: white;
}

.back-btn{
    border: 1px solid red;
    background-color: white;
    margin: 10px;
}

.back-btn:hover{
    color: white;
    background-color: sienna;
}

.editscreen h1{
    color: white;
}

/* product list screen */

.btn-add button{
    background-color: yellowgreen;
}

.btn-add button:hover{
    background-color: tomato;
}

.product-list{
    background-color: #272343;
    color: white;
    border-radius: 10px;
}


.user-edit a{
    color: white;
}


.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 30px;
  }
  
  .search-form {
    height: 50px;
    border-radius: 4px; /* Adjust border-radius if needed */
  }
  
  .search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  

  .button-container {
  }

  .custom-navbar {
    background-color: #2B3B17 !important;
  }
  
  .custom-navbar .navbar-nav .nav-link,
  .custom-navbar .navbar-brand,
  .custom-navbar .nav-dropdown-item {
    color: white !important;
  }
  
  .custom-navbar .navbar-nav .nav-link:hover,
  .custom-navbar .navbar-brand:hover,
  .custom-navbar .nav-dropdown-item:hover {
    color: #ddd !important;
  }
  
  /* Nav.css */

/* General container styling */
.user-list-container {
    background-color: #f8f9fa;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Table styling */
  .table-lg {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .table-light th {
    background-color: #e9ecef;
    color: #495057;
    font-weight: bold;
  }
  
  /* Hover effect for email links */
  .email-link {
    color: #0d6efd;
    text-decoration: none;
  }
  
  .email-link:hover {
    text-decoration: underline;
  }
  
  /* Centering and spacing buttons */
  .btn {
    margin-left: 0.5rem;
  }
  
  /* Add light styling for action buttons */
  .btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd;
  }
  
  .btn-outline-primary:hover {
    background-color: #0d6efd;
    color: white;
  }
  
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    background-color: #dc3545;
    color: white;
  }
  
  /* Nav.css */

/* Align the container to the center of the page */
.vh-100 {
    height: 100vh;
  }
  
  /* Styling the card */
  .card {
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  /* Styling for the back button link */
  .btn-link {
    color: #28a745;
    text-decoration: none;
  }
  
  .btn-link:hover {
    color: #218838;
    text-decoration: underline;
  }
  
  /* Form field styling */
  .form-control {
    border-radius: 8px;
  }
  
  /* Checkbox alignment */
  .form-check-input {
    margin-left: 0;
    transform: scale(1.2);
  }
  
  /* Smooth hover effect for the submit button */
  button[type='submit'] {
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  button[type='submit']:hover {
    background-color: #218838;
  }
  
  /* Nav.css */

/* Card styling */
.card {
    max-width: 1100px;
    margin: 20px auto;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Table styling */
  .table-hover tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .table-light th {
    background-color: #f8f9fa;
    color: #343a40;
  }
  
  /* Action buttons */
  button {
    border-radius: 6px;
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  .btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  
  /* Create product button */
  .btn-success {
    background-color: #28a745;
    border: none;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  /* Pagination spacing */
  .pagination {
    justify-content: center;
  }
  
  /* Nav.css */

/* Card layout */
.card {
    max-width: 1100px;
    margin: 20px auto;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Table styling */
  .table-hover tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .table-light th {
    background-color: #e9ecef;
    color: #343a40;
  }
  
  /* Text styling for status */
  .text-success {
    font-weight: bold;
  }
  
  .text-danger {
    font-weight: bold;
  }
  
  /* Buttons */
  button {
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  /* Align table cells to the center */
  .text-center {
    text-align: center;
  }

  /* Order Screen Styling */
.order-screen {
    margin-top: 20px;
  }
  
  .section-header h2 {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .btn-block {
    border-radius: 8px;
    margin-top: 10px;
  }
  
  /* img {
    max-height: 80px;
    object-fit: cover;
  } */
  
  .product-screen {
  margin-top: 30px;
}

.product-image {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-name {
  font-weight: bold;
}

.product-price {
  color: #28a745;
  font-size: 1.25rem;
}

.stock-status.in-stock {
  color: green;
}

.stock-status.out-of-stock {
  color: red;
}

.review-section {
  margin-top: 50px;
}

.write-review-toggle {
  background-color: transparent;
  border: none;
  font-weight: bold;
  text-align: left;
}

/* Profile Screen Container */
.profile-screen-container {
  margin-top: 30px;
  max-width: 1000px; /* Ensures it doesn't stretch too much */
  margin-left: auto;
  margin-right: auto;
}

/* Profile and Order History Cards */
.profile-card,
.order-history-card {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Table Styling */
.order-table thead {
  background-color: #f8f9fa;
  font-weight: bold;
}

.order-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Form Control Styling */
.form-control {
  border-radius: 8px;
}

/* Submit Button */
button.btn-block {
  border-radius: 8px;
  transition: background-color 0.3s;
}

button.btn-block:hover {
  background-color: #218838;
}
