.edit-screen {
  padding: 20px;
}

.back-btn {
  margin-bottom: 20px;
}

.image-preview {
  margin-bottom: 1rem;
}

.image-preview img {
  max-height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.images-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
}

.preview-image-container {
  position: relative;
  display: inline-block;
}

.remove-image-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0 6px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 1;
}

.form-group {
  margin-bottom: 1.5rem;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
} 