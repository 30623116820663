/* HeroSection.css */
.hero-section {
    background-color: #f8f9fa;
    padding: 80px 0;
    overflow: hidden;
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .hero-carousel {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  
  .hero-carousel .carousel-control-prev,
  .hero-carousel .carousel-control-next {
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
  
  .hero-carousel .carousel-indicators {
    bottom: -50px;
  }
  
  .hero-carousel .carousel-indicators button {
    background-color: #77a21d;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
  }
  
  .text-content {
    text-align: left;
    padding: 20px;
  }
  
  .top-seller {
    color: #77a21d;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
    color: #2b2b52;
    margin: 20px 0;
  }
  
  .description {
    font-size: 1.125rem;
    margin: 20px 0 30px;
    color: #6c757d;
    line-height: 1.6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .shop-now-btn {
    background-color: #77a21d;
    border: none;
    font-size: 1rem;
    padding: 12px 30px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .shop-now-btn:hover {
    background-color: #5e8e17;
    transform: translateY(-2px);
  }
  
  .hero-image-wrapper {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .hero-link {
    display: inline-block;
    text-decoration: none;
    z-index: 10;
    position: relative;
  }
  
  /* Responsive Breakpoints */
  @media (max-width: 1200px) {
    .hero-section {
      padding: 60px 0;
    }
  
    h1 {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 992px) {
    .hero-section {
      padding: 50px 0;
      min-height: 500px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .hero-image-wrapper {
      height: 350px;
    }
  }
  
  @media (max-width: 768px) {
    .hero-section {
      padding: 40px 0;
      text-align: center;
      min-height: 400px;
    }
  
    .text-content {
      text-align: center;
      margin-bottom: 30px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    .top-seller {
      font-size: 1rem;
    }
  
    .hero-image-wrapper {
      height: 300px;
    }
  
    .hero-carousel .carousel-control-prev,
    .hero-carousel .carousel-control-next {
      width: 40px;
      height: 40px;
    }
  
    .hero-carousel .carousel-indicators {
      bottom: -30px;
    }
  }
  
  @media (max-width: 576px) {
    .hero-section {
      padding: 30px 0;
      min-height: 350px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .description {
      font-size: 0.9rem;
    }
  
    .shop-now-btn {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  
    .hero-image-wrapper {
      height: 250px;
    }
  }
  